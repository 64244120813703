/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/linkedBaner.png";
import inline from "../../../images/solarbuddy-inside.jpg";
import leftIcon from "../../../images/Left Button.svg";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="SOLARBUDDY | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="SOLARBUDDY"
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "SolarBuddy is a global impact organisation uniting a global community to illuminate the futures of all children living in energy poverty.",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "SolarBuddy strives to provide equal opportunities for all children. They believe that in order to thrive, every child should have access to clean, reliable and safe energy.",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "One of the biggest obstacles faced in today’s world is extreme energy poverty; one of the worst and most unknown forms of poverty. It is a complex challenge, impacting the health, wellbeing, educational outcomes, economic stability and environment of the communities experiencing it. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        butlletdesign="yes"
        statements={[
          {
            id: "",
            title: "SolarBuddy works towards two goals: ",
            heading: [],
            subhead: "YES",
            designclass: "ga-ml--10",
          },
          {
            id: "",
            title: "Gift solar devices to children living in extreme poverty",
            heading: [],
          },
          {
            id: "",
            title:
              "Educate and inspire people to be agents of change to end extreme energy poverty by 2030 ",
            heading: [],
          },
        ]}
      />
      <ValuesCollection
        key=""
        title="What we did "
        className="bg-light-blue pt-5"
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        statements={[
          {
            id: "",
            title: "Assembled 200 SolarBuddies in August 2022 ",
            heading: [],
          },
          {
            id: "",
            title:
              "Currently in discussions for future partnerships and further pro bono work",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "Impacted 1000 lives – working off a 1:5 ratio, 5 people are directly impacted by donation of a light, lasting for 10 years (which contributes to 1.46 million extra study hours )",
              },
            ],
            type: "heading-three",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Current and future state planning – Prepared a view of current and desired future state with NACCHO and nbn (considering connectivity infrastructure and digital healthcare requirements)",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Development of prioritisation criteria – Developed framework for assessing which ACCHOs to prioritise for connectivity and digital capability uplift investment to ensure targeted value for money ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Connectivity and toolset requirements – Defined must have tools, services, connectivity improvements",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Cost benefit analysis – Summarised options for capability uplift, benefits and outcomes, and with breakdown of cost estimates",
        //     heading: [],
        //   },
        // ]}
        heading={[
          {
            children: [
              {
                text: "Impacted 1000 lives – working off a 1:5 ratio, 5 people are directly impacted by donation of a light, lasting for 10 years (which contributes to 1.46 million extra study hours) ",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Impact achieved"
      />
      <p className="col-span-12 col-start-2 text-center p-20px ga-mt--30">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href="https://www.solarbuddy.org/" className="text-dark-blue">
            https://www.solarbuddy.org/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
